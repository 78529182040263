import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgZorroAntdMobileModule } from 'ng-zorro-antd-mobile';

/** 配置 ng-zorro-antd-mobile 国际化 **/
import { LOCAL_PROVIDER_TOKEN, en_US } from 'ng-zorro-antd-mobile';

// ngrx
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { siteReducer } from './ngrx/reducers/site.reduce';

//service
import { StorageService } from './services/storage.service';
import { ApiService } from './services/api.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    StoreModule.forRoot({ site: siteReducer }),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroAntdMobileModule,
  ],
  providers: [
    StorageService,
    ApiService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCAL_PROVIDER_TOKEN, useValue: en_US },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
