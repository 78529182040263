import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: any;
  private STORAGE_PREFIX = 'TOOLSAFETYMEETING_';

  constructor() {
    this.storage = localStorage;
  }

  /**
   * Use for storing values, stringifies values
   */
  public set(key: string, value: any) {
    switch (typeof value) {
      case 'function':
        throw new Error(
          'Yeah, so, functions cannot be saved into localStorage.'
        );
      case 'string':
      case 'number':
      case 'boolean':
      case 'undefined':
        this.storage.setItem(this.STORAGE_PREFIX + key, value || '');
        break;
      default:
        this.storage.setItem(this.STORAGE_PREFIX + key, JSON.stringify(value));
        break;
    }
  }

  public get(key: string, defaultValue?: any) {
    let item = this.storage.getItem(this.STORAGE_PREFIX + key) || defaultValue;

    // We do not need to parse the default value
    if (item !== defaultValue) {
      try {
        item = JSON.parse(item || '{}');
      } catch (e) {
        // Item is probably a string like 'Hello world' which cannot be parsed,
        // no hard feelings, just don't let the app crash
      }
    }

    return item;
  }

  public remove(key: string) {
    this.storage.removeItem(this.STORAGE_PREFIX + key);
  }
}
