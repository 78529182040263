import { Action } from '@ngrx/store';

/*
  定義 ActionTypes , Action
 */

export enum ActionTypes {
  SETSITE = '[Tab1 Component] SETSITE',
}

export class SiteAction implements Action {
  type: string;
  payload: Site;
}

export class SETSITE implements Action {
  readonly type = ActionTypes.SETSITE;
  constructor(
    public payload: {
      siteId: string;
      street: string;
      city: string;
      workersCount: number;
      siteMgrCount: number;
    }
  ) {}
}

export interface Site {
  siteId: string;
  street: string;
  city: string;
  workersCount: number;
  siteMgrCount: number;
}
