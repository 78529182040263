import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  APIURL: string = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.APIURL = environment.API;
    console.log(this.APIURL);
  }

  //新增/更新 施工人數
  addWorkerCount(_result) {
    const body = JSON.stringify(_result);
    console.log('JSON.stringify');
    console.log(body);
    let queryUrl = this.APIURL + '/toolbox-safety-meeting/add/workerscount';
    return this.http.post(queryUrl, body, this.httpOptions);
  }

  //上傳勞工安全守則結果
  addSafetyRuleResult(_result) {
    const body = JSON.stringify(_result);
    // console.log('JSON.stringify');
    // console.log(body);
    let queryUrl = this.APIURL + '/toolbox-safety-meeting/add/safetyrule';
    return this.http.post(queryUrl, body, this.httpOptions);
  }

  // 查詢今日已上傳簽名施工人員清單 by site id
  selectSignedWorkerListBySiteId(_siteId) {
    let queryUrl = this.APIURL + `/toolbox-safety-meeting/today/${_siteId}`;
    console.log(queryUrl);
    return this.http.get(queryUrl);
  }

  // init DB
  initDB() {
    let queryUrl = this.APIURL + `/toolbox-safety-meeting/init/db`;
    console.log(queryUrl);
    return this.http.get(queryUrl);
  }

  // 取得今日案場&人數資料 by site id
  selectStitAndCountInfoBySiteId(_siteId) {
    let queryUrl =
      this.APIURL + `/toolbox-safety-meeting/setting/today/${_siteId}`;
    console.log(queryUrl);
    return this.http.get(queryUrl);
  }
}
