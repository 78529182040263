import { Action } from '@ngrx/store';
import { ActionTypes, SiteAction } from '../actions/site.action';

// 初始數據
export const initialState = {
  siteId: '---',
  street: '---',
  city: '---',
  workersCount: 0,
  siteMgrCount: 0,
};

// action Reducer
export function siteReducer(state = initialState, action: SiteAction) {
  console.log('===> siteReducer');

  const { type, payload } = action;
  console.log(type);

  switch (type) {
    case ActionTypes.SETSITE:
      return {
        ...state,
        siteId: payload.siteId,
        street: payload.street,
        city: payload.city,
        workersCount: payload.workersCount,
        siteMgrCount: payload.siteMgrCount,
      };
    default:
      return state;
  }
}
