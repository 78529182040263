import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

// https://ionicframework.com/blog/start-build-deploy-your-first-capacitor-pwa-with-ionic/
export class AppComponent {
  constructor() {}
}
